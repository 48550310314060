import gql from "graphql-tag";

const SPENDING_DATA = gql`
  fragment SpendingData on Spending {
    id
    amount
    note
    date
    currency {
      id
      name
    }
    category {
      id
      name
    }
  }
`;

export const GET_SPENDINGS = gql`
  query($categoryId: ID, $offset: Int, $limit: Int) {
    spendings(categoryId: $categoryId, offset: $offset, limit: $limit) {
      ...SpendingData
    }
  }
  ${SPENDING_DATA}
`;

export const GET_OPTIONS = gql`
  query {
    currencies {
      id
      name
    }
    categories {
      id
      name
    }
  }
`;

export const ADD_SPENDING = gql`
  mutation AddSpending(
    $amount: Float!
    $note: String!
    $categoryId: ID!
    $currencyId: ID!
    $date: String!
  ) {
    addSpending(
      amount: $amount
      note: $note
      categoryId: $categoryId
      currencyId: $currencyId
      date: $date
    ) {
      ...SpendingData
    }
  }
  ${SPENDING_DATA}
`;

export const UPDATE_SPENDING = gql`
  mutation UpdateSpending(
    $id: ID!
    $amount: Float!
    $note: String!
    $categoryId: ID!
    $currencyId: ID!
    $date: String!
  ) {
    updateSpending(
      id: $id
      amount: $amount
      note: $note
      categoryId: $categoryId
      currencyId: $currencyId
      date: $date
    ) {
      ...SpendingData
    }
  }
  ${SPENDING_DATA}
`;

export const DELETE_SPENDING = gql`
  mutation DeleteSpending($id: ID!) {
    deleteSpending(id: $id)
  }
`;
