import React from "react";
import { useHistory, Link } from "react-router-dom";
import { Card, Alert, notification, Button } from "antd";
import styled from "styled-components";
import useAsync from "../hooks/useAsync";
import { register } from "../api/auth";
import UserForm from "../components/UserForm";

const Panel = styled(Card)`
  max-width: 450px;
  margin: 3em auto;
`;

const FormAlert = styled(Alert)`
  margin-bottom: 1.5em;
`;

export default function Register() {
  const history = useHistory();

  const { execute, pending, error } = useAsync(register, {
    onCompleted: () => {
      notification.success({ message: "Register successfully!" });
      history.push("/login");
    },
  });

  const handleSubmit = (value) => {
    execute(value);
  };

  const getErrorMessage = () =>
    error.response
      ? error.response.data.error
      : "Server temporarily unavailable. please try again later.";

  return (
    <Panel title="Register">
      {error && <FormAlert type="error" message={getErrorMessage()} />}

      <UserForm
        onSubmit={handleSubmit}
        uploading={pending}
        secondaryBtn={
          <Link to="/login">
            <Button type="link">To Login</Button>
          </Link>
        }
      />
    </Panel>
  );
}
