import axios from "axios";

export const login = ({ email, password }) => {
  return axios({
    method: "post",
    url: "/auth/login",
    data: {
      email,
      password,
    },
  });
};

export const register = async ({ email, password }) => {
  return axios({
    method: "post",
    url: "/auth/register",
    data: {
      email,
      password,
    },
  });
};
