import React from "react";
import PropTypes from "prop-types";
import { Form, Input, Button } from "antd";

function UserForm({ uploading, onSubmit, secondaryBtn }, ref) {
  return (
    <Form ref={ref} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} onFinish={onSubmit}>
      <Form.Item
        label="Email"
        name="email"
        rules={[{ required: true, message: "Please input the email!" }, { type: "email" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[{ required: true, message: "Please input the password!" }]}
      >
        <Input type="password" />
      </Form.Item>

      <Form.Item
        wrapperCol={{
          xs: { offset: 0 },
          sm: { offset: 6 },
        }}
      >
        <Button type="primary" htmlType="submit" disabled={uploading}>
          {uploading ? "Uploading..." : "Submit"}
        </Button>
        {secondaryBtn}
      </Form.Item>
    </Form>
  );
}

export default React.forwardRef(UserForm);

UserForm.defaultProps = {
  uploading: false,
};

UserForm.propTypes = {
  uploading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  secondaryBtn: PropTypes.element.isRequired,
};
