import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Empty } from "antd";
import styled from "styled-components";
import dayjs from "dayjs";
import SpendingCard from "./SpendingCard";

const Date = styled.div`
  margin: 1em 0.25em 0.5em;
  color: #274863;
`;

export default function SpendingList({ spendings, onDelete, onUpdate }) {
  const spendingsGroupByDate = useMemo(() => {
    return spendings.reduce((groupByDate, spending) => {
      const date = dayjs(Number(spending.date)).format("YYYY-MM-DD");

      if (groupByDate[date]) {
        return {
          ...groupByDate,
          [date]: [...groupByDate[date], spending],
        };
      }

      return {
        ...groupByDate,
        [date]: [spending],
      };
    }, {});
  }, [spendings]);

  if (spendings.length === 0) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  return Object.entries(spendingsGroupByDate).map(([groupByDate, dateSpendings]) => (
    <div key={groupByDate}>
      <Date>{groupByDate}</Date>

      {dateSpendings.map((spending) => {
        const { id, date, category, currency, amount, note } = spending;
        return (
          <SpendingCard
            key={id}
            date={date}
            category={category.name}
            currency={currency.name}
            amount={amount}
            note={note}
            onDelete={() => onDelete(id)}
            onUpdate={() => onUpdate(spending)}
          />
        );
      })}
    </div>
  ));
}

SpendingList.propTypes = {
  spendings: PropTypes.arrayOf(PropTypes.object).isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};
