import { useMutation } from "@apollo/react-hooks";
import { ADD_SPENDING } from "../api/spending";
import { insertSpending } from "../utils/spending";

export default (queryOption, onCompleted) => {
  return useMutation(ADD_SPENDING, {
    onCompleted,
    update(cache, { data: { addSpending } }) {
      const { spendings } = cache.readQuery(queryOption);

      const newSpendings = [...spendings];
      insertSpending(newSpendings, addSpending);

      cache.writeQuery({
        ...queryOption,
        data: { spendings: newSpendings },
      });
    },
  });
};
