import { useState, useCallback } from "react";

export default (asyncFunction, { onCompleted = () => {}, onError = () => {} } = {}) => {
  const [pending, setPending] = useState(false);
  const [value, setValue] = useState(null);
  const [error, setError] = useState(null);

  const execute = useCallback(
    (...args) => {
      setPending(true);
      setValue(null);
      setError(null);

      return asyncFunction(...args)
        .then((response) => {
          setValue(response);
          onCompleted(response);
        })
        .catch((err) => {
          setError(err);
          onError(err);
        })
        .finally(() => setPending(false));
    },
    [asyncFunction, onCompleted, onError]
  );

  return { execute, pending, value, error };
};
