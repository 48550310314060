import React from "react";
import { useHistory, Link } from "react-router-dom";
import { Card, Alert, Button } from "antd";
import styled from "styled-components";
import useAsync from "../hooks/useAsync";
import { login } from "../api/auth";
import UserForm from "../components/UserForm";

const Panel = styled(Card)`
  max-width: 450px;
  margin: 3em auto;
`;

const FormAlert = styled(Alert)`
  margin-bottom: 1.5em;
`;

export default function Login() {
  const history = useHistory();

  const { execute, pending, error } = useAsync(login, {
    onCompleted: () => {
      history.push("/");
    },
  });

  const handleSubmit = (value) => {
    execute(value);
  };

  return (
    <Panel title="Login">
      {error && <FormAlert type="error" message="The credentials are not valid." />}

      <UserForm
        onSubmit={handleSubmit}
        uploading={pending}
        secondaryBtn={
          <Link to="/register">
            <Button type="link">To Register</Button>
          </Link>
        }
      />
    </Panel>
  );
}
