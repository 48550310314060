import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Button } from "antd";
import { EditOutlined } from "@ant-design/icons";
import moment from "moment";
import Loading from "../components/Loading";
import SpendingList from "../components/SpendingList";
import SpendingModal from "../components/SpendingModal";
import UpdateSpendingModal from "../components/UpdateSpendingModal";
import useSpendings from "../hooks/useSpendings";
import useSpendingDelete from "../hooks/useSpendingDelete";

const SpendingWrapper = styled.div`
  padding: 0.5em 0.5em 6em;
`;

const AddSpendingBtn = styled(Button)`
  position: fixed;
  z-index: 100;
  bottom: 27px;
  right: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  padding: 0;
  border-radius: 50%;
  box-shadow: 0 1.9px 2.9px -3px rgba(0, 0, 0, 0.071), 0 4.5px 7.3px -3px rgba(0, 0, 0, 0.102),
    0 9px 14.9px -7px rgba(0, 0, 0, 0.128), 0 17.8px 30.7px -6px rgba(0, 0, 0, 0.159),
    0 47px 85px -13px rgba(0, 0, 0, 0.23);
`;

const LoadMoreBtn = styled(Button)`
  margin-top: 2em;
`;

export default function Spending() {
  const history = useHistory();
  const { loading, data, loadMore, loadMoreDisabled, queryOption } = useSpendings(30, {
    onUnauthenticated: () => {
      history.push("/login");
    },
  });
  const showLoadMoreBtn = !loading && !loadMoreDisabled;

  const loadMoreBtnRef = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          loadMore();
        }
      },
      { threshold: [0.25] }
    );

    if (loadMoreBtnRef.current) {
      observer.observe(loadMoreBtnRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [loadMore]);

  const [deleteSpending] = useSpendingDelete(queryOption);

  const [modalVisible, setModalVisible] = useState(false);

  const [updateModalVisible, setUpdateModalVisible] = useState(false);
  const [currentSpending, setCurrentSpending] = useState(null);

  const openUpdateModal = (spending) => {
    setCurrentSpending({
      ...spending,
      date: moment(Number(spending.date)),
      categoryId: spending.category.id,
      currencyId: spending.currency.id,
    });
    setUpdateModalVisible(true);
  };

  const closeUpdateModal = () => {
    setCurrentSpending(null);
    setUpdateModalVisible(false);
  };

  return (
    <SpendingWrapper>
      <AddSpendingBtn
        onClick={() => setModalVisible(true)}
        type="primary"
        icon={<EditOutlined />}
      />

      <SpendingModal
        queryOption={queryOption}
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
      />
      {currentSpending && (
        <UpdateSpendingModal
          queryOption={queryOption}
          spending={currentSpending}
          visible={updateModalVisible}
          onClose={closeUpdateModal}
        />
      )}

      {data && (
        <SpendingList
          spendings={data.spendings}
          onDelete={(id) => deleteSpending({ variables: { id } })}
          onUpdate={(spending) => openUpdateModal(spending)}
        />
      )}

      {loading && <Loading />}

      {showLoadMoreBtn && (
        <LoadMoreBtn ref={loadMoreBtnRef} type="primary" onClick={loadMore}>
          Load More
        </LoadMoreBtn>
      )}
    </SpendingWrapper>
  );
}
