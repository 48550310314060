import React from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import { Form, Input, Button, Select, DatePicker } from "antd";
import Loading from "./Loading";
import { GET_OPTIONS } from "../api/spending";

function SpendingForm({ initialValues, uploading, onSubmit }, ref) {
  const { loading, error, data } = useQuery(GET_OPTIONS);

  if (loading) return <Loading />;
  if (error) return `Error! ${error.message}`;

  const { currencies, categories } = data;

  return (
    <Form
      ref={ref}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
      initialValues={initialValues}
      onFinish={onSubmit}
    >
      <Form.Item
        label="日期"
        name="date"
        rules={[{ required: true, message: "Please input the date!" }]}
      >
        <DatePicker />
      </Form.Item>
      <Form.Item
        label="金額"
        name="amount"
        rules={[
          { required: true, message: "Please input the amount!" },
          {
            validator: (_, value) =>
              value > 0
                ? Promise.resolve()
                : Promise.reject(new Error("The amount should be greater then zero!")),
          },
        ]}
      >
        <Input type="number" />
      </Form.Item>

      <Form.Item
        label="幣別"
        name="currencyId"
        rules={[{ required: true, message: "Please select the currency!" }]}
      >
        <Select>
          {currencies.map((currency) => (
            <Select.Option key={currency.id} value={currency.id}>
              {currency.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label="分類"
        name="categoryId"
        rules={[{ required: true, message: "Please select the category!" }]}
      >
        <Select>
          {categories.map((category) => (
            <Select.Option key={category.id} value={category.id}>
              {category.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label="詳細描述"
        name="note"
        rules={[{ required: true, message: "Please input the description!" }]}
      >
        <Input.TextArea row={3} />
      </Form.Item>

      <Form.Item
        wrapperCol={{
          xs: { offset: 0 },
          sm: { offset: 6 },
        }}
      >
        <Button type="primary" htmlType="submit" disabled={uploading}>
          {uploading ? "Uploading..." : "Submit"}
        </Button>
      </Form.Item>
    </Form>
  );
}

export default React.forwardRef(SpendingForm);

SpendingForm.defaultProps = {
  initialValues: {},
  uploading: false,
};

SpendingForm.propTypes = {
  initialValues: PropTypes.objectOf(PropTypes.any),
  uploading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};
