export const popSpending = (spendings, newSpending) => {
  const index = spendings.findIndex((spending) => spending.id === newSpending.id);
  spendings.splice(index, 1);
};

export const insertSpending = (spendings, newSpending) => {
  const spendingsLength = spendings.length;
  const lastSpending = spendings[spendingsLength - 1];
  const newSpendingDate = Number(newSpending.date);

  if (spendings.length === 0 || newSpendingDate <= Number(lastSpending.date)) {
    spendings.push(newSpending);
    return;
  }

  for (let i = 0; i < spendingsLength; i += 1) {
    const spending = spendings[i];

    if (newSpendingDate > Number(spending.date)) {
      spendings.splice(i, 0, newSpending);
      break;
    }
  }
};
