import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Modal } from "antd";
import moment from "moment";
import SpendingForm from "./SpendingForm";
import useSpendingUpdate from "../hooks/useSpendingUpdate";

export default function UpdateSpendingModal({ visible, onClose, spending, queryOption }) {
  const formRef = useRef(null);

  const [updateSpending, { loading: mutationLoading }] = useSpendingUpdate(queryOption, () => {
    formRef.current.resetFields();
    onClose();
  });

  return (
    <Modal title="變更消費紀錄" visible={visible} centered footer={null} onCancel={() => onClose()}>
      <SpendingForm
        key={spending.id}
        ref={formRef}
        initialValues={spending}
        uploading={mutationLoading}
        onSubmit={(value) => {
          updateSpending({
            variables: {
              ...value,
              id: spending.id,
              amount: Number(value.amount),
            },
          });
        }}
      />
    </Modal>
  );
}

UpdateSpendingModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  spending: PropTypes.shape({
    id: PropTypes.string.isRequired,
    date: PropTypes.instanceOf(moment).isRequired,
    categoryId: PropTypes.string.isRequired,
    currencyId: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    note: PropTypes.string.isRequired,
  }).isRequired,
  queryOption: PropTypes.shape({
    query: PropTypes.object,
  }).isRequired,
};
