import { useCallback, useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { GET_SPENDINGS } from "../api/spending";

export default (size, { onUnauthenticated = () => {} } = {}) => {
  const [loadMoreDisabled, setLoadMoreDisabled] = useState(false);

  const result = useQuery(GET_SPENDINGS, {
    variables: {
      offset: 0,
      limit: size,
    },
    fetchPolicy: "cache-and-network",
    onError({ networkError }) {
      if (networkError && networkError.statusCode === 401) {
        onUnauthenticated();
      }

      setLoadMoreDisabled(true);
    },
    onCompleted(data) {
      if (data.spendings.length === 0) {
        setLoadMoreDisabled(true);
      }
    },
  });

  const { data, fetchMore, variables } = result;

  const loadMore = useCallback(() => {
    if (loadMoreDisabled) {
      return;
    }

    fetchMore({
      variables: {
        offset: data.spendings.length,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        if (fetchMoreResult.spendings.length === 0) {
          setLoadMoreDisabled(true);
        }

        return {
          ...prev,
          spendings: [...prev.spendings, ...fetchMoreResult.spendings],
        };
      },
    });
  }, [fetchMore, loadMoreDisabled, data]);

  return {
    ...result,
    loadMore,
    loadMoreDisabled,
    queryOption: {
      query: GET_SPENDINGS,
      variables,
    },
  };
};
