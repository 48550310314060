import React from "react";
import styled from "styled-components";
import { Spin } from "antd";

const LoadingWrapper = styled.div`
  padding: 3em 0;
  text-align: center;
`;

export default function Loading() {
  return (
    <LoadingWrapper>
      <Spin />
    </LoadingWrapper>
  );
}
