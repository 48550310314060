import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Button, Popconfirm } from "antd";
import { DeleteOutlined, FormOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

const btnWidth = 42;

const SpendingCardWrapper = styled.div`
  position: relative;
  border-radius: 2px;
  border: 1px solid #eee;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
  transition: padding 0.3s ease-in-out;
  user-select: none;

  &.active {
    padding-right: ${btnWidth * 2}px;
  }
`;

const Actions = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
`;

const ActionBtn = styled(Button)`
  width: ${btnWidth}px;
  height: auto;
`;

const CardBody = styled.div`
  position: relative;
  display: flex;
  background: white;
`;

const Cost = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0.5em;
  text-align: right;

  .price {
    font-size: 20px;
  }

  .currency {
    margin-left: 0.5em;
    font-size: 12px;
  }
`;

const Details = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0.75em;
`;

const Info = styled.div`
  margin-right: 1.25em;
  text-align: center;
  font-size: 12px;
`;

const Note = styled.div`
  white-space: pre-wrap;
  word-break: break-all;
`;

export default function SpendingCard({
  date,
  category,
  currency,
  amount,
  note,
  onDelete,
  onUpdate,
}) {
  const [active, setActive] = useState(false);
  const toggleActive = () => {
    setActive(!active);
  };

  return (
    <SpendingCardWrapper className={active ? "active" : ""}>
      <Actions>
        <ActionBtn onClick={onUpdate} icon={<FormOutlined />} />
        <Popconfirm title="Delete this record?" onConfirm={onDelete}>
          <ActionBtn type="primary" danger icon={<DeleteOutlined />} />
        </Popconfirm>
      </Actions>

      <CardBody onClick={toggleActive}>
        <Details>
          <Info>
            {dayjs(Number(date)).format("HH:mm")}
            <div className="category">{category}</div>
          </Info>
          <Note>{note}</Note>
        </Details>

        <Cost>
          <div className="price">
            {amount}
            <span className="currency">{currency}</span>
          </div>
        </Cost>
      </CardBody>
    </SpendingCardWrapper>
  );
}

SpendingCard.defaultProps = {
  onDelete: () => {},
  onUpdate: () => {},
};

SpendingCard.propTypes = {
  date: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  note: PropTypes.string.isRequired,
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func,
};
