import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Modal } from "antd";
import moment from "moment";
import SpendingForm from "./SpendingForm";
import useSpendingCreate from "../hooks/useSpendingCreate";

export default function SpendingModal({ visible, onClose, queryOption }) {
  const formRef = useRef(null);

  const [addSpending, { loading: mutationLoading }] = useSpendingCreate(queryOption, () => {
    formRef.current.resetFields();
    onClose();
  });

  return (
    <Modal title="記錄新消費" visible={visible} centered footer={null} onCancel={() => onClose()}>
      <SpendingForm
        ref={formRef}
        uploading={mutationLoading}
        initialValues={{ date: moment() }}
        onSubmit={(value) => {
          addSpending({
            variables: {
              ...value,
              amount: Number(value.amount),
            },
          });
        }}
      />
    </Modal>
  );
}

SpendingModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  queryOption: PropTypes.shape({
    query: PropTypes.object,
  }).isRequired,
};
