import { useMutation } from "@apollo/react-hooks";
import { DELETE_SPENDING } from "../api/spending";
import { popSpending } from "../utils/spending";

export default (queryOption, onCompleted) => {
  return useMutation(DELETE_SPENDING, {
    onCompleted,
    update(cache, { data: { deleteSpending } }) {
      const { spendings } = cache.readQuery(queryOption);

      const newSpendings = [...spendings];
      popSpending(newSpendings, { id: deleteSpending });

      cache.writeQuery({
        ...queryOption,
        data: { spendings: newSpendings },
      });
    },
  });
};
